







































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import { AttachmentHostType, FundProjectProgressDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import Album from "@/components/Album/index.vue";

@Component({
  components: {
    AbSelect,
    Album,
  },
})
export default class CreateFundProjectProgress extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundProjectProgressDto = {
    id: 0,
  };
  dataId?: number = 0;
  showAlbum = false;
  selectedPhotos: string[] = [];
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.FundApply;
  }

  created() {
    if (this.$route.params.id) {
      api.fundProjectProgress
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
          if (this.form && this.form.images) {
            this.selectedPhotos = this.form.images.split(",");
          }
        });
    }
    if (this.$route.params.projectId) {
      this.form.projectId = Number(this.$route.params.projectId);
    }
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.selectedPhotos && this.selectedPhotos.length > 0) {
          this.form.images = this.selectedPhotos.join(",");
        }
        if (this.form!.id) {
          await api.fundProjectProgress.update({
            body: this.form,
          });
        } else {
          await api.fundProjectProgress.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  handleShowAlbum() {
    this.showAlbum = true;
  }

  handleSelectedPhotos(e: any) {
    this.selectedPhotos = this.selectedPhotos
      ? this.selectedPhotos.concat(e)
      : e;
    this.showAlbum = false;
  }

  unChoosePhoto(index: number) {
    this.selectedPhotos.splice(index, 1);
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "标题必填",
        trigger: "blur",
      },
    ],
    projectId: [
      {
        required: true,
        message: "项目必填",
        trigger: "blur",
      },
    ],
    summary: [
      {
        required: true,
        message: "简述必填",
        trigger: "blur",
      },
    ],
    picture: [
      {
        required: true,
        message: "图片必填",
        trigger: "blur",
      },
    ],
    publishedTime: [
      {
        required: true,
        message: "发布时间必填",
        trigger: "blur",
      },
    ],
  };
}
